export const ofuscarLink = (event) => {
  const element = event.currentTarget;
  const url = element.dataset.url;
  if(url.substring(0,22) !== "https://getbillage.com" && url.substring(0,26) !== "https://www.getbillage.com" && url.substring(0,1)!=="/" && url.substring(0,1) !== "#" && url.substring(0,2) !== ".."){
    window.open(url,"_blank");
  }else{
    location.href = url;
  }

};

export const urlServer = "https://www.getbillage.com/";
