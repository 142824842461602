/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO ({
  description,
  robotsNofollow,
  robotsNoindex,
  autor,
  lang,
  meta,
  title,
  canonical,
  openGraphSite,
  openGraphType,
  openGraphUrl,
  openGraphImg,
  openGraphImgWidth,
  openGraphImgHeight,
  dctermsCreated,
  dctermsModified,
  twitterImg,
  twitterImgWidth,
  twitterImgHeight,
  alternates,
  appJson,
  idPage
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  // const metaTitle = title;
  if (canonical !== undefined) {
    canonical = canonical.replace("https://www.getbillage.com/wp", "");
    canonical = canonical.replace("https://getbillage.com/wp", "");
    canonical = canonical.replace("https://www.getbillage.com", "");
    canonical = canonical.replace("http://www.getbillage.com/wp", "");
    canonical = canonical.replace("http://getbillage.com/wp", "");
    canonical = canonical.replace("http://www.getbillage.com", "");
  }

  // {canonical.substring(canonical.length-1)==='/'?canonical=canonical.substring(0,canonical.length -1):canonical=canonical}
  let metaCanonical = "https://www.getbillage.com" + canonical;
  if (
    idPage !== 82 &&
    idPage !== 17248 &&
    idPage !== 17247 &&
    metaCanonical.charAt(metaCanonical.length - 1) === "/"
  ) {
    metaCanonical = metaCanonical.substring(0, metaCanonical.length - 1);
    if (metaCanonical.substring(metaCanonical.length - 4) === "home") { metaCanonical = metaCanonical.substring(0, 4); }
  }
  const metaOpengraphSite = openGraphSite;
  const metaOpengraphType = openGraphType;
  if (openGraphUrl !== undefined) {
    openGraphUrl = openGraphUrl.replace("https://www.getbillage.com/wp", "");
    openGraphUrl = openGraphUrl.replace("https://getbillage.com/wp", "");
    openGraphUrl = openGraphUrl.replace("https://www.getbillage.com", "");
    openGraphUrl = openGraphUrl.replace("http://www.getbillage.com/wp", "");
    openGraphUrl = openGraphUrl.replace("http://getbillage.com/wp", "");
    openGraphUrl = openGraphUrl.replace("http://www.getbillage.com", "");
  }
  //let metaOpengraphUrl = "https://www.getbillage.com" + openGraphUrl;
  let metaOpengraphUrl = "https://www.getbillage.com" + canonical;
  if (
    idPage !== 82 &&
    idPage !== 17248 &&
    idPage !== 17247 &&
    metaOpengraphUrl.charAt(metaOpengraphUrl.length - 1) === "/"
  ) {
    metaOpengraphUrl = metaOpengraphUrl.substring(
      0,
      metaOpengraphUrl.length - 1
    );
  }
  const metaOpengraphImg = openGraphImg;
  const metaOpengraphImgWidth = openGraphImgWidth;
  const metaOpengraphImgHeight = openGraphImgHeight;
  const metaDctermsCreated = dctermsCreated;
  const metaDctermsModified = dctermsModified;
  const metaLang = lang;
  let metaUrl = "https://www.getbillage.com" + canonical;
  if (idPage !== 82 && metaUrl.charAt(metaUrl.length - 1) === "/") {
    metaUrl = metaUrl.substring(0, metaUrl.length - 1);
  }
  const metaTwitterImg = twitterImg;
  const metaTwitterImgWidth = twitterImgWidth;
  const metaTwitterImgHeight = twitterImgHeight;
  const metaRobotsNofollow = robotsNofollow;
  const metaRobotsNoindex = robotsNoindex;
  // AditionalMeta
  let dcterms = [];
  if (typeof metaDctermsCreated !== "undefined") {
    dcterms.push({
      name: "DCTERMS.created",
      content: metaDctermsCreated
    });
  }
  if (typeof metaDctermsModified !== "undefined") {
    dcterms.push({
      name: "DCTERMS.modified",
      content: metaDctermsModified
    });
  }

  const link = [
    {
      rel: "shortcut icon",
      href: "/favicon-48x48.ico"
    },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/apple-touch-icon.png"
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "144x144",
      href: "/favicon-144x144.png"
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/favicon-32x32.png"
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "/favicon-16x16.png"
    },
    {
      rel: "manifest",
      href: "/site.webmanifest"
    },
    {
      rel: "mask-icon",
      href: "/safari-pinned-tab.svg",
      color: "#1ed04c"
    },
    {
      rel: "canonical",
      href: metaCanonical
    },
    {
      rel: "image_src",
      href: metaOpengraphImg
    }
  ];
  if (metaCanonical === "https://www.getbillage.com/es/blog" || metaCanonical === "https://www.getbillage.com/ca/blog" || metaCanonical === "https://www.getbillage.com/en/blog" || metaCanonical === "https://www.getbillage.com/es/" || metaCanonical === "https://www.getbillage.com/ca/" || metaCanonical === "https://www.getbillage.com/en/") {
    link.push({
      rel: "alternate",
      type: "application/rss+xml",
      title: (metaLang === "es" ? "Feed del blog de Billage con consejos y noticies para Pymes y Autónomos" : (metaLang === "ca" ? "Feed del bloc de Billage amb consells i notícies per a Pimes i Autònoms" : "Billage blog feed with tips and news for SMEs and Self-Employed")),
      href: (metaLang === "es" ? "https://www.getbillage.com/es/news/feed" : (metaLang === "ca" ? "https://www.getbillage.com/ca/news/feed" : "https://www.getbillage.com/en/news/feed"))
    });
  }
  if (alternates !== undefined && alternates.length > 0) {
    link.push({
      rel: "alternate",
      hreflang: metaLang,
      href: metaCanonical
    });
  }
  for (const i in alternates) {
    if (alternates[i].seo !== undefined) {
      let hrefAlternate = alternates[i].seo.canonical;
      const auxLang = alternates[i].locale.locale.substring(0, 2);
      if (hrefAlternate.charAt(hrefAlternate.length - 1) === "/"
      ) {
        hrefAlternate = hrefAlternate.substring(0, hrefAlternate.length - 1);
      }
      if (hrefAlternate.substring(hrefAlternate.length - 4) === "home") { hrefAlternate = "/" + auxLang + "/"; }
      link.push({
        rel: "alternate",
        hrefLang: auxLang,
        href: "https://www.getbillage.com" + hrefAlternate
      });
    }
  }
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      // base={{
      //   href:`https://www.getbillage.com`,
      // }}
      link={link}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        // {
        //  name: `generator`,
        //  content: `Gatsby js`,
        // },
        {
          name: "msapplication-TileColor",
          content: "#ffffff"
        },
        {
          name: "theme-color",
          content: "#ffffff"
        },
        {
          name: "msapplication-TileColor",
          content: "#ffffff"
        },
        {
          name: "theme-color",
          content: "#ffffff"
        },
        {
          name: "description",
          content: metaDescription
        },
        {
          name: "robots",
          content: metaRobotsNofollow + "," + metaRobotsNoindex
          // content: `nofollow,noindex`,
        },
        {
          itemprop: "name",
          content: defaultTitle
        },
        {
          itemprop: "description",
          content: metaDescription
        },
        {
          itemprop: "image",
          content: metaOpengraphImg
        },
        {
          name: "DCTERMS.abstract",
          content: metaDescription
        },
        {
          name: "DCTERMS.language",
          content: metaLang
        },
        {
          name: "DCTERMS.identifier",
          content: metaUrl
        },
        {
          name: "DCTERMS.format",
          content: "text/html"
        },
        {
          property: "og:title",
          content: title
        },
        {
          property: "og:site_name",
          content: metaOpengraphSite
        },
        {
          property: "og:url",
          content: metaOpengraphUrl
        },
        {
          property: "og:description",
          content: metaDescription
        },
        {
          property: "og:type",
          content: metaOpengraphType
        },
        {
          property: "og:image",
          content: metaOpengraphImg
        },
        {
          property: "og:image:width",
          content: metaOpengraphImgWidth
        },
        {
          property: "og:image:height",
          content: metaOpengraphImgHeight
        },
        {
          name: "twitter:card",
          content: "summary"
        },
        {
          name: "twitter:creator",
          content: site.siteMetadata?.author || ""
        },
        {
          name: "twitter:title",
          content: title
        },
        {
          name: "twitter:description",
          content: metaDescription
        },
        {
          name: "twitter:url",
          content: metaUrl
        },
        {
          name: "twitter:image",
          content: metaTwitterImg
        },
        {
          name: "twitter:image:width",
          content: metaTwitterImgWidth
        },
        {
          name: "twitter:image:height",
          content: metaTwitterImgHeight
        }
      ].concat(meta).concat(dcterms)}
    >
      {appJson !== undefined && appJson !== null
        ? appJson.map((appJsonElement, i) =>
        <script key={i} type="application/ld+json">{`${appJsonElement}`}</script>

        )
        : null}

      <script>
        {"window.intercomSettings = {app_id: \"sbkq18wo\"};"}
      </script>
      <script>{`// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/sbkq18wo'
      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/sbkq18wo';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
      </script>

    </Helmet>
  );
}

// <script>{`
// var userLang = navigator.language || navigator.userLanguage;
// dataLayer = [{
// 'userLang': userLang,
// }];
// `}</script>
// <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
// new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
// j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
// 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
// })(window,document,'script','dataLayer','GTM-TTXBSX');
// `}</script>
//
// <script>{
// `function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
// h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
// (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
// })(window,document.documentElement,'async-hide','dataLayer',4000,{'GTM-TTXBSX':true});
// `}</script>

SEO.defaultProps = {
  lang: "es",
  meta: [],
  link: [],
  description: ""
  // base:`https://www.getbillage.com`,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  base: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  link: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

export default SEO;
