import { Link, graphql, useStaticQuery } from "gatsby"
import { ofuscarLink } from "../utils.js"
import React from "react";
const dictionary = require("../dic/dic.json")
const Header = props => {


  const data = useStaticQuery(
    graphql`
      {
        allWpMenu {
          edges {
            node {
              slug
              name
              id
              menuItems {
                nodes {
                  label
                  title
                  url
                  order
                  parentId
                  cssClasses
                  childItems {
                    nodes {
                      label
                      title
                      url
                      target
                      description
                      cssClasses
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  var menus = data.allWpMenu.edges;
  var menu_principal = [];
  var menu_ticketbai = [];
  for (var i in menus) {
    if (menus[i].node.name === "menu_principal_" + props.langPage) {
      menu_principal = menus[i].node.menuItems.nodes;
    }
    if (menus[i].node.name === "menu_ticketbai") {
      menu_ticketbai = menus[i].node.menuItems.nodes;
    }
  }

  const menuPrincipal = menu_principal.map((node, i) => {
    const hasChilds =
      node.childItems.nodes.length !== 0;

    if (node.parentId === null) {
      return (
        <li
          key={i}
          className={
            (hasChilds
              ? "dropdown dropdown-submenu has-sub "
              : "") + "hidden-sm hidden-xs"
          }
        >
          <div className="int-item">
            {node.cssClasses.includes("ofuscar")
              ? <span
                onClick={ofuscarLink}
                data-url={"" + (node.url.charAt(node.url.length - 1) === "/"
                  ? node.url.substring(0, node.url.length - 1)
                  : (node.url.charAt(node.url.length - 1) === "#" ? node.url.substring(0, node.url.length - 2) : node.url)) + ""
                }
                className={
                  "dropbtn ofuscado " + (hasChilds ? " dropdown-toggle" : "")
                }
              >
                <p>{node.label}</p>

              </span>
              : <Link
                to={node.url.charAt(node.url.length - 1) === "/"
                  ? node.url.substring(0, node.url.length - 1)
                  : (node.url.charAt(node.url.length - 1) === "#" ? node.url.substring(0, node.url.length - 2) : node.url)
                }
                className={
                  "dropbtn " + (hasChilds ? " dropdown-toggle" : "")
                }
              >
                <p>{node.label}</p>
              </Link>
            }

            <div className="dropmobile"></div>
            {hasChilds ? (
              <div className={"dropdown-content " + node.title}>
                <ul className="">
                  {node.childItems.nodes.map((node, i) => {
                    // const final_url= node.url.substring(0,node.url.length-1);
                    const url = node.url.replace("/category", "");
                    return (
                      <li key={i} className="">
                        <Link
                          to={
                            url.charAt(node.url.length - 1) === "/"
                              ? url.substring(
                                0,
                                node.url.length - 1
                              )
                              : url
                          }
                          className={
                            props.idPage !== undefined &&
                              node.id === props.idPage
                              ? "active"
                              : ""
                          }
                          title={node.label}
                          target={node.target}
                          rel={
                            node.target === "_blank"
                              ? "noopener noreferrer"
                              : null
                          }
                        >
                          <div className="menu-descripcion">
                            <div className="menu-descripcion-titulo">
                              {node.label}
                            </div>
                            <div className="menu-descripcion-texto">
                              <p>{node.title}</p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}
          </div>
        </li>
      );
    }

    return null;
  });

  const myPath = typeof window !== "undefined" ? window.location.pathname : "";

  const menuTicketBai = menu_ticketbai.map((node, i) => {
    if (node.parentId === null) {
      return (
        <li
          key={i}
          className="hidden-sm hidden-xs"
        >
          <div className="int-item">

            {(node.url + "/" === myPath || node.url === myPath)
              ? (<p className="menu-active-unlinked">{node.label}</p>)
              : (<Link
                to={
                  node.url.charAt(node.url.length - 1) === "/"
                    ? node.url.substring(0, node.url.length - 1)
                    : (node.url.charAt(node.url.length - 1) === "#" ? node.url.substring(0, node.url.length - 2) : node.url)
                }
                className={(node.url + "/" === myPath ? "active" : "") + "dropbtn"}
              >
                <p>{node.label}</p>
              </Link>)
            }
          </div>
        </li>
      );
    }
    return null;
  });

  // console.log(props);
  const hasMenuTicketBai = !!(props.clase_pagina !== null && props.clase_pagina !== undefined && props.clase_pagina.includes("landing-ticketbai"));
  const menu = hasMenuTicketBai ? menuTicketBai : menuPrincipal;

  return (
    <header
      className={`header-home header-white ${props.clase_pagina !== undefined ? "cabecera-" + props.clase_pagina : ""
        }`}
    >
      <div className="topnav">
        <div className="container">

          <div className="topnav-left">
            <span className="topnav-intro-desktop">{dictionary[props.langPage].topnav_intro}</span>
            <span className="topnav-intro-mobile">{dictionary[props.langPage].topnav_intro_mobile}</span>
            <span className="btn btn-topnav" onClick={ofuscarLink} data-url="/es/kit-digital">{dictionary[props.langPage].topnav_cta}</span>
          </div>
          <div className="topnav-right">
            {dictionary[props.langPage].topnav_llamanos} <strong>93 247 99 59</strong>
          </div>

        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="logo col-6 col-md-2">
            <Link to={`/${props.langPage}/`} title="Homepage Billage">
              <img
                src="/images/logo-billage-color.svg"
                alt="Programa de facturación con CRM y tareas: Billage, el software para optimizar tu negocio"
                title="Programa de facturación con CRM y tareas: Billage, el software para optimizar tu negocio"
                width="130"
                height="25"
              />
            </Link>
          </div>
          <nav className="menu-main col-6  col-md-10">
            <span
              onClick={ofuscarLink}
              data-url="https://app.getbillage.com/saas/register/view-register"
              className="boton boton-header-cta ofuscado"
              target="_blank"
              rel="noopener noreferrer"
            >
              {dictionary[props.langPage].empieza_gratis}
            </span>
            <button
              id="pull"
              className="hamburger-menu"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <ul className="menuItems">
              {menu}

              <li className="boto menu-option-login">
                <span
                  onClick={ofuscarLink}
                  data-url="https://app.getbillage.com/saas"
                  rel="alternate"
                  hrefLang="es"
                  className="buttonLogin ofuscado"
                >
                  {dictionary[props.langPage].iniciar_sesion}
                </span>
              </li>

              <li className="boto menu-option-pruebalo">
                <span
                  onClick={ofuscarLink}
                  data-url="https://app.getbillage.com/saas/register/view-register"
                  className="boton boton-arrow ofuscado"
                  rel="noopener noreferrer"
                >
                  <span>{dictionary[props.langPage].empieza_gratis}</span>
                </span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header
