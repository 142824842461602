import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"

const languageIds = require("../dic/ids.json")
const dictionary = require("../dic/dic.json")

const Footer = ({ langPage, alternates }) => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage(filter: { databaseId: { in: [259, 24429, 24438] } }) {
          edges {
            node {
              databaseId
              title
              page_content {
                contentRaw
              }
            }
          }
        }
      }
    `
  )

  var footer = null
  for (var i in data.allWpPage.edges) {
    if (
      data.allWpPage.edges[i].node.databaseId === languageIds[langPage].footer
    ) {
      footer = data.allWpPage.edges[i].node
      break
    }
  }

  return (
    <footer>
      <div className="container">
        {footer !== null ? (
          <div
            className="innerFooter"
            dangerouslySetInnerHTML={{ __html: footer.page_content.contentRaw }}
          />
        ) : null}
      </div>
      <div className="footer-bottom">
        <div className="container">
          <img
            src="/images/logo-cegid-white.svg"
            title="Programa de facturación con CRM y tareas: Billage, el software para optimizar tu negocio"
            alt="Programa de facturación con CRM y tareas: Billage, el software para optimizar tu negocio"
            className="footer-logo"
          />

          <span className="footer-bottom-text">
            {dictionary[langPage].footer_bottom_text}
          </span>

          {
            <div className={"btn-group sel-idioma dropdown" + (alternates === undefined || alternates.length === 0 ? " blocked-lang" : "")}>
              <a href="#" className="btn btn-idioma dropbtn dropdown-toggle">
                Idioma:
                <span className="lang-es">Español</span>
                <span className="lang-en">English</span>
                <span className="lang-ca">Català</span>
                <i className="fa fa-angle-down" aria-hidden="true"></i>
              </a>
              <div className="dropdown-content">
                <ul>
                  {alternates !== undefined && alternates.length > 0
                    ? alternates.map(alternate => {

                      if (alternate.seo !== undefined) {
                        var href_alternate = alternate.seo.canonical;
                        if (href_alternate.charAt(href_alternate.length - 1) === "/"
                        ) {
                          href_alternate = href_alternate.substring(0, href_alternate.length - 1)
                        }
                        if (href_alternate.substring(href_alternate.length - 4) === "home") {
                          href_alternate = "/" + alternate.locale.locale.substring(0, 2) + "/";
                        }

                        return (<li key={alternate.locale.locale.substring(0, 2)}>
                          <Link
                            hrefLang={alternate.locale.locale.substring(0, 2)}
                            to={href_alternate}
                            className={
                              "lang_sel_sel icl-" +
                              alternate.locale.locale.substring(0, 2)
                            }
                          >
                            {alternate.locale.locale.substring(0, 2) === "ca"
                              ? "Català"
                              : null}
                            {alternate.locale.locale.substring(0, 2) === "en"
                              ? "English"
                              : null}
                            {alternate.locale.locale.substring(0, 2) === "es"
                              ? "Español"
                              : null}
                          </Link>
                        </li>);
                      }
                      return null
                    })
                    : null}
                </ul>
              </div>
            </div>
          }
        </div>
      </div>
    </footer>
  )
}

export default Footer
