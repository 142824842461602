
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { Component } from "react"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"
class Layout extends Component {
  constructor (props) {
    super(props);
    this.state = {
    };
    this.dataLayerEvents = this.dataLayerEvents.bind(this);
  }



  addScript(url) {
    const script = document.createElement("script")
    script.src = url
    script.async = false
    document.body.appendChild(script)
  }

  componentDidMount () {
    /*this.addScriptGoogle(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-593CQ9M');
    `);*/

    this.addScript("/customjs/jquery-3.6.0.min.js");
    this.addScript("/customjs/mobile.js");
    //console.log("test");
    let allLinks = document.getElementsByTagName("a");
    for (let i = 0; i < allLinks.length; i++) {
      var link = allLinks[i].getAttribute("href");
      if (typeof link !== "undefined" && link != null) {
        //console.log(link.substring(0,1));
        if (link.substring(0, 22) !== "https://getbillage.com"
          && link.substring(0, 26) !== "https://www.getbillage.com"
          && link.substring(0, 1) !== "/"
          && link.substring(0, 1) !== "#"
          && link.substring(0, 2) !== "..") {
          allLinks[i].setAttribute("target", "_blank");

        }
      }
    }
    let allLinksofuscats = document.getElementsByTagName("a");
    for (let i = 0; i < allLinksofuscats.length; i++) {
      var link = allLinksofuscats[i].getAttribute("onclick");
      if (typeof link !== "undefined" && link != null && link.substring(0, 13) === "location.href") {

        if (link.substring(15, 37) !== "https://getbillage.com"
          && link.substring(15, 41) !== "https://www.getbillage.com"
          && link.substring(15, 16) !== "/"
          && link.substring(15, 16) !== "#"
          && link.substring(15, 17) !== "..") {
          allLinksofuscats[i].setAttribute("target", "_blank");
          //console.log("entra");
        }
      }
    }
    let allLinksofuscats2 = document.getElementsByTagName("span");
    for (let i = 0; i < allLinksofuscats2.length; i++) {
      var link = allLinksofuscats2[i].getAttribute("onclick");
      if (typeof link !== "undefined" && link != null && link.substring(0, 13) === "location.href") {

        if (link.substring(15, 37) !== "https://getbillage.com"
          && link.substring(15, 41) !== "https://www.getbillage.com"
          && link.substring(15, 16) !== "/"
          && link.substring(15, 16) !== "#"
          && link.substring(15, 17) !== "..") {
          allLinksofuscats2[i].setAttribute("onclick", "window.open('" + link.substring(15, (link.length - 1)) + "','_blank')")
          //allLinksofuscats2[i].setAttribute("target","_blank");
          //console.log("entra");
        }
      }
    }
    let allLinksofuscats3 = document.getElementsByTagName("span");
    for (let i = 0; i < allLinksofuscats3.length; i++) {
      var link = allLinksofuscats3[i].getAttribute("data-url");
      if (typeof link !== "undefined" && link != null) {

        if (link.substring(0, 22) !== "https://getbillage.com"
          && link.substring(0, 26) !== "https://www.getbillage.com"
          && link.substring(0, 1) !== "/"
          && link.substring(0, 1) !== "#"
          && link.substring(0, 2) !== "..") {
          //allLinksofuscats2[i].setAttribute("data-url","");
          //allLinksofuscats3[i].removeAttribute("data-url");
          //allLinksofuscats3[i].setAttribute("onclick","window.open('"+link.substring(0,(link.length))+"','_blank')")

          //allLinksofuscats3[i].setAttribute("target","_blank");
          //console.log("entra");
        }
      }
    }
    this.dataLayerEvents();
  }

  dataLayerEvents () {
    Array.from(document.querySelectorAll(".menu-main .menuItems .int-item a")).forEach((menuButton, i) => {
      menuButton.addEventListener("click", function (ev) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "menu_header_selection",
          menuheader: {
            action: {
              page: ev.currentTarget.textContent, // Este es un campo dinámico
              action: "selección",
              category: "menu header selección"
            }
          }
        });
      });
    });
    const currentPage = this.props.currentPage;
    document.querySelector(".menu-main .menuItems .buttonLogin").addEventListener("click", function (ev) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "iniciar_sesion",
        iniciarsesion: {
          action: {
            page: currentPage, // Este es un campo dinámico
            action: "Iniciar sesión"
          }
        }
      });
    });
    document.querySelector(".menu-main .menuItems .menu-option-pruebalo").addEventListener("click", function (ev) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "probar_gratis",
        probargratis: {
          action: {
            page: currentPage, // Este es un campo dinámico
            action: "Probar gratis"
          }
        }
      });
    });
  }

  removeScript(scriptToremove) {
    let allsuspects = document.getElementsByTagName("script");
    for (let i = allsuspects.length; i >= 0; i--) {
      if (allsuspects[i] && allsuspects[i].getAttribute("src") !== null
        && allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1) {
        allsuspects[i].parentNode.removeChild(allsuspects[i])
      }
    }
  }

  componentWillUnmount() {
    this.removeScript("/customjs/jquery-3.6.0.min.js");
    this.removeScript("/customjs/mobile.js");

  }

  render() {
    // const data = useStaticQuery(graphql`
    //   query SiteTitleQuery {
    //     site {
    //       siteMetadata {
    //         title
    //       }
    //     }
    //   }
    // `)

    return <>
      {/*}<div dangerouslySetInnerHTML={{__html: `
             <!-- Google Tag Manager (noscript) -->
             <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TTXBSX"
             height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
             <!-- End Google Tag Manager (noscript) -->`}}/>*/}
      {/*<div dangerouslySetInnerHTML={{__html:`<script src="https://consent.cookiefirst.com/banner.js" data-cookiefirst-key="74dd1ff3-b95f-4668-9f87-e3084d5b47c8"></script>`}}/>*/}
      <div dangerouslySetInnerHTML={{
        __html: `<!-- Add the code below inside the <body> tags of your HTML page -->
              <!--<script>
              window.VIDEOASK_EMBED_CONFIG = {
                "kind": "widget",
                "url": "https://www.videoask.com/f6e7is9iq",
                "options": {
                  "widgetType": "VideoThumbnailExtraLarge",
                  "text": "",
                  "backgroundColor": "#7D00FE",
                  "position": "bottom-left",
                  "dismissable": false
                }
              }
              </script>
              <script src="https://www.videoask.com/embed/embed.js"></script>-->
              <!-- Important!!! In order to allow your videoask to open up on the same page when interacting with the widget, your website must use the secure https:// protocol (i.e have an SSL certificate). Otherwise, when clicking the widget, a new tab will be automatically opened in the browser.
              -->
              <script>
              function getUrlVars() {
                                          var vars = {};
                                          var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
                                                          vars[key] = value;
                                          });
                                          return vars;
                            }

                            var idReseller = getUrlVars()["idReseller"];
                            if(idReseller){

                                          var d = new Date();
                                          d.setTime(d.getTime()+(90*24*60*60*1000));
                                          var expires = "expires="+d.toGMTString();
                                          document.cookie = "idReseller=" + idReseller + "; " + expires + ";domain=getbillage.com; path=/";
                            }

                            var referrer = document.referrer;
                            if(referrer){

                                          if (document.cookie.indexOf("es.billage.referrer") == -1){
                                                          var d = new Date();
                                                          d.setTime(d.getTime()+(90*24*60*60*1000));
                                                          var expires = "expires="+d.toGMTString();
                                                          document.cookie = "com.getbillage.referrer=" + referrer + "; " + expires + ";domain=getbillage.com; path=/";
                                          }
                            }


                            var utmsource = getUrlVars()["utm_source"];

                            if(utmsource){
                                          var d = new Date();
                                          d.setTime(d.getTime()+(90*24*60*60*1000));
                                          var expires = "expires="+d.toGMTString();
                                          document.cookie = "com.getbillage.utmsource=" + utmsource + "; " + expires + ";domain=getbillage.com; path=/";
                            }


                            var utmmedium = getUrlVars()["utm_medium"];
                            if(utmmedium){
                                          var d = new Date();
                                          d.setTime(d.getTime()+(90*24*60*60*1000));
                                          var expires = "expires="+d.toGMTString();
                                          document.cookie = "com.getbillage.utmmedium=" + utmmedium + "; " + expires + ";domain=getbillage.com; path=/";
                            }


                            var utmcampaign = getUrlVars()["utm_campaign"];
                            if(utmcampaign){

                                          var d = new Date();
                                          d.setTime(d.getTime()+(90*24*60*60*1000));
                                          var expires = "expires="+d.toGMTString();
                                          document.cookie = "com.getbillage.utmcampaign=" + utmcampaign + "; " + expires + ";domain=getbillage.com; path=/";
                            }


                            var utmterm = getUrlVars()["utm_term"];
                            if(utmterm){

                                          var d = new Date();
                                          d.setTime(d.getTime()+(90*24*60*60*1000));
                                          var expires = "expires="+d.toGMTString();
                                          document.cookie = "com.getbillage.utmterm=" + utmterm + "; " + expires + ";domain=getbillage.com; path=/";
                            }
            </script>  `}} />
      <Header idPage={this.props.idPage} langPage={this.props.langPage} clase_pagina={this.props.clase_pagina} />
      <div id={this.props.id_pagina} className={this.props.clase_pagina}>


        <main>
          <div className="contingut">
            {this.props.children}
          </div>
        </main>

        <Footer langPage={this.props.langPage} alternates={this.props.alternates} />
      </div>
    </>;
  }

}

export default Layout
